<template>
  <div class="youth-fixed-fee" v-if="hasPerm('youth.view_seanceinscription')">
    <div class="youth-fixed-fee-table" :id="'youth-fixed-fee-' + id">
      <div class="filter-bar no-print">
        <b-row>
          <b-col cols="8">
            <seances-list-filter
              @changed="onListFilterChanged($event)"
              @loaded="onListFilterLoaded($event)"
              stacked
              multi
              :id="id"
            >
            </seances-list-filter>
          </b-col>
          <b-col>
            <div class="no-print">
              <date-frame-selector @change="onDateFrameChanged">
              </date-frame-selector>
              <div class="custom-range-holder no-print">
                <div><b>Tranches de QF</b></div>
                <b-row>
                  <b-col>
                    <number-input v-model="familyLevel1" id="familyLevel1" allow-null @focusout="loadData()">
                    </number-input>
                  </b-col>
                  <b-col>
                    <number-input v-model="familyLevel2" id="familyLevel2" allow-null @focusout="loadData()">
                    </number-input>
                  </b-col>
                </b-row>
              </div>
              <div class="custom-range-holder no-print">
                <div><b>Âge</b></div>
                <b-row>
                  <b-col>
                    <number-input v-model="age1" id="age1" allow-null @focusout="loadData()">
                    </number-input>
                  </b-col>
                  <b-col>
                    <number-input v-model="age2" id="age2" allow-null @focusout="loadData()">
                    </number-input>
                  </b-col>
                </b-row>
              </div>
              <div style="margin-top: 10px">
                <b-checkbox v-model="showCode" id="showCode">Afficher le code séance</b-checkbox>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <loading-gif :loading-name="loadingName"></loading-gif>
      <div v-if="!isLoading(loadingName)">
        <div v-if="items.length === 0" class="empty-text">
          <div v-if="isValid">Aucune données disponibles pour ce filtre</div>
          <div v-else>Veuillez sélectionner via le filtre les données à afficher</div>
        </div>
        <div v-if="items.length" class="section">
          <b-row>
            <b-col>
              <div class="section-header"><b>{{ subTitle() }}</b></div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <table class="full-table table-bordered table-striped">
                <tr>
                  <th></th>
                  <th>Analytique</th>
                  <th :colspan="showCode ? 2 : 1">Séance</th>
                  <th>Prix</th>
                  <th>Inscrits</th>
                  <th>Montant</th>
                </tr>
                <tr v-for="item of items" :key="item.id">
                  <td class="number">{{ item.id }}</td>
                  <td>{{ item.analyticAccount.getLabel() }}</td>
                  <td v-if="showCode">{{ item.seance.code }}</td>
                  <td>{{ item.seance.getBaseName() }}</td>
                  <td class="number">{{ item.price | currency }}</td>
                  <td class="number">{{ item.individuals }}</td>
                  <td class="number">{{ item.amount | currency }}</td>
                </tr>
                <tr>
                  <th :colspan="showCode ? 4 : 3"></th>
                  <th class="number">
                    {{ totalPrices() | currency }}
                  </th>
                  <th class="number">
                    {{ totalIndividuals() }}
                  </th>
                  <th class="number">
                    {{ totalAmount() | currency }}
                  </th>
                </tr>
              </table>
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapActions, mapMutations } from 'vuex'
import LoadingGif from '@/components/Controls/LoadingGif'
import SeancesListFilter from '@/components/Seances/Config/SeancesListFilter'
import DateFrameSelector from '@/components/DateRange/DateFrameSelector.vue'
import NumberInput from '@/components/Controls/NumberInput.vue'
import { BackendMixin } from '@/mixins/backend'
import { currency, dateToString } from '@/filters/texts'
import { BackendApi } from '@/utils/http'
import { sum } from '@/utils/math'
import { makeAnalyticAccount } from '@/types/payments'
import { makeSeance } from '@/types/youth'

export default {
  name: 'youth-fixed-fee-table',
  mixins: [BackendMixin],
  components: {
    NumberInput,
    LoadingGif,
    SeancesListFilter,
    DateFrameSelector,
  },
  props: {
    id: String,
    printMode: Boolean,
  },
  data() {
    return {
      youthHomes: [],
      seanceTypes: [],
      periods: [],
      familyLevel1: null,
      familyLevel2: null,
      age1: null,
      age2: null,
      startDate: null,
      endDate: null,
      showCode: false,
      items: [],
    }
  },
  computed: {
    isValid() {
      return (
        this.youthHomes.length && this.seanceTypes.length && this.periods.length
      )
    },
    loadingName() {
      return 'youth-fixed-fee-' + this.id
    },
  },
  mounted() {
  },
  watch: {
    printMode: function() {},
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    async loadData() {
      this.startLoading(this.loadingName)
      await this.loadStats()
      this.endLoading(this.loadingName)
    },
    subTitle() {
      let texts = []
      if (this.youthHomes) {
        texts = texts.concat(this.youthHomes.map(elt => elt.name))
      }
      if (this.seanceTypes) {
        texts = texts.concat(this.seanceTypes.map(elt => elt.name))
      }
      if (this.periods) {
        texts = texts.concat(this.periods.map(elt => elt.name))
      }
      let dateTexts = []
      if (this.startDate) {
        dateTexts.push('du ' + dateToString(this.startDate, 'll'))
      }
      if (this.endDate) {
        dateTexts.push('au ' + dateToString(this.endDate, 'll'))
      }
      if (dateTexts.length) {
        texts.push(dateTexts.join(' '))
      }
      let levelTexts = []
      if (this.familyLevel1) {
        levelTexts.push('du QF' + this.familyLevel1)
      }
      if (this.familyLevel2) {
        levelTexts.push('au QF' + this.familyLevel2)
      }
      if (levelTexts.length) {
        texts.push(levelTexts.join(' '))
      }
      let ageTexts = []
      if (this.age1) {
        ageTexts.push('de ' + this.age1 + (this.age1 > 1 ? ' ans' : ' an'))
      }
      if (this.age2) {
        ageTexts.push('à ' + this.age2 + (this.age2 > 1 ? ' ans' : ' an'))
      }
      if (ageTexts.length) {
        texts.push(ageTexts.join(' '))
      }
      return texts.join(' - ')
    },
    async loadStats() {
      this.analyticAccounts = []
      this.items = []
      if (this.isValid) {
        let url = '/stats/api/youth-fixed-fee/'
        let data = {
          'youth_homes': this.youthHomes.map(elt => elt.id),
          'seance_types': this.seanceTypes.map(elt => elt.id),
          'periods': this.periods.map(elt => elt.id),
          'start_date': this.startDate ? dateToString(this.startDate, 'YYYY-MM-DD') : null,
          'end_date': this.endDate ? dateToString(this.endDate, 'YYYY-MM-DD') : null,
        }
        if (this.familyLevel1 || this.familyLevel2) {
          data.family_levels = [this.familyLevel1, this.familyLevel2]
        }
        if (this.age1 || this.age2) {
          data.ages = [this.age1, this.age2]
        }
        const backendApi = new BackendApi('post', url)
        try {
          const resp = await backendApi.callApi(data)
          this.items = resp.data.map(
            (elt, index) => {
              return {
                id: index + 1,
                seance: makeSeance(elt.seance),
                price: elt.price,
                analyticAccount: makeAnalyticAccount(elt['analytic_account']),
                amount: elt.amount,
                individuals: elt.individuals,
              }
            }
          )
        } catch (err) {
          await this.addError(this.getErrorText(err))
        }
      }
    },
    onListFilterChanged(evt) {
      if (evt.id === this.id) {
        this.youthHomes = evt.youthHomes
        this.seanceTypes = evt.seanceTypes
        this.periods = evt.periods
        this.loadData()
      }
    },
    onChange() {
      this.loadData()
    },
    onListFilterLoaded(evt) {
    },
    onDateFrameChanged(event) {
      this.startDate = event.startDate
      this.endDate = event.endDate
      this.loadData()
    },
    totalAmount() {
      return sum(this.items.map(item => item.amount))
    },
    totalPrices() {
      return sum(this.items.map(item => item.price))
    },
    totalIndividuals() {
      return sum(this.items.map(item => item.individuals))
    },
  },
}
</script>

<style scoped lang="less">
.youth-fixed-fee-table {
  .section {
    margin: 10px 0;
  }
  .section-header {
    background: #ccc;
    padding: 5px;
  }

  table.full-table {
    width: 100%;
  }

  table.full-table td, table.full-table th {
    padding: 3px 3px;
    font-size: 12px;
  }

  table.full-table th {
    background: #ddd;
  }

  table.full-table tr:nth-child(odd) th {
    background: #ccc;
  }

  table.full-table td.value-col, table.full-table th.value-col {
    max-width: 40px;
    overflow: hidden;
  }

  table.full-table th.header-col {
    font-size: 9px;
  }

  .sub-title {
    margin-bottom: 10px;
    font-weight: bold;
  }

  .number {
    text-align: right;
  }

  .filter-bar {
    background: #e0e0e0;
    padding: 10px 5px;
    border-top: solid 1px #888;
  }

  .filter-bar-optional {
    border-top: solid 1px #888;
    background: #e0e0e0;
    padding: 10px 5px;
  }

  .small-filter-title {
    font-size: 12px;
    margin-bottom: 5px;
  }
  th {
    text-align: center;
  }
  th.number {
    text-align: right;
  }
  th.col {
    width: 100px;
  }
}
</style>
