<template>
  <div class="youth-fixed-fee" v-if="hasPerm('youth.view_seanceinscription')">
    <div>
      <page-header
        :title="'Forfaits ' + youthHomeLabel"
        icon="fas fa-layer-group"
        :links="getLinks()"
        :print-hour="true"
      >
      </page-header>
      <div  ref="printMe">
        <div ref="excelMe">
          <youth-fixed-fee-table id="1" :print-mode="printMode"></youth-fixed-fee-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment'
import { mapActions, mapMutations } from 'vuex'
import PageHeader from '@/components/Layout/PageHeader'
import YouthFixedFeeTable from '@/components/Stats/YouthFixedFeeTable.vue'
import { BackendMixin } from '@/mixins/backend'
import store from '@/store'
import { openDocument } from '@/utils/http'

export default {
  name: 'youth-welfare-stats',
  mixins: [BackendMixin],
  components: {
    YouthFixedFeeTable,
    PageHeader,
  },
  props: {
  },
  data() {
    return {
      statsLoading: 'youth-welfare-list',
      printMode: false,
    }
  },
  computed: {
    youthHomeLabel() {
      return store.getters.youthHomeLabel
    },
  },
  mounted() {
  },
  watch: {
  },
  methods: {
    ...mapActions(['addError', 'addSuccess']),
    ...mapMutations(['startLoading', 'endLoading']),
    getLinks() {
      return [
        {
          id: 1,
          label: 'Pdf',
          callback: this.printMe,
          icon: 'fa fa-file-pdf',
          cssClass: (this.isLoading(this.statsLoading)) ? 'btn-secondary disabled' : 'btn-secondary',
        },
        {
          id: 2,
          label: 'Excel',
          callback: this.excelMe,
          icon: 'fa fa-file-excel',
          cssClass: (this.isLoading(this.statsLoading)) ? 'btn-secondary disabled' : 'btn-secondary',
        }
      ]
    },
    async printMe() {
      const docUrl = '/documents/standard/<key>/pdf/?colors=1'
      const docSlug = 'forfaits-acm-' + moment().format('YYYY-MM-DD')
      const that = this
      this.printMode = true
      this.$nextTick(
        async function() {
          try {
            const docContent = that.$refs.printMe.innerHTML.toString()
            await openDocument(docUrl, docSlug, docContent, 'aides')
            that.printMode = false
          } catch (err) {
            await that.addError(that.getErrorText(err))
          }
        }
      )
    },
    async excelMe() {
      const docUrl = '/documents/table-to-excel/<key>/'
      const docSlug = 'forfaits-acm-' + moment().format('YYYY-MM-DD')
      const docContent = this.$refs.excelMe.innerHTML.toString()
      try {
        await openDocument(docUrl, docSlug, docContent)
      } catch (err) {
        await this.addError(this.getErrorText(err))
      }
    },
  },
}
</script>

<style scoped lang="less">
table {
  width: 100%;
}
table tr td,
table tr th {
   border: solid 1px #f0f0f0;
   padding: 4px;
}
.sub-title {
  margin-bottom: 10px;
  font-weight: bold;
}
.section {
  padding: 5px;
  border: solid 1px #888;
  margin-bottom: 20px;
}
.block-link {
  text-align: right;
  margin-bottom: 5px;
}
</style>
